import * as React from 'react';
import { useCallback } from 'react';
import Layout from '../layouts/application';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TaskStatus from '../../components/shared/task_status';
import submitted from 'images/submitted.png';
import newReport from 'images/new_report.png';
import pendingMeeting from 'images/pending_meeting.png';
import ReportIcon from '../../components/progress_report/icon';

const Container = (props) => {
  // eslint-disable-next-line
  let style = {
    padding: '0 20px',
    margin: '1px',
    backgroundColor: 'white',
    cursor: 'move',
  };

  // eslint-disable-next-line
  const reportIcon = (status) => {
    if (status == 'pending_lead') {
      return <img src={newReport} height="12px" style={{ position: 'absolute', right: -13, top: 13 }} />;
    } else if (status == 'pending_reviewer') {
      return <img src={submitted} height="12px" style={{ position: 'absolute', right: -13, top: 13 }} />;
    } else if (status == 'pending_meeting') {
      return <img src={pendingMeeting} height="12px" style={{ position: 'absolute', right: -13, top: 13 }} />;
    }
  };

  // eslint-disable-next-line
  const renderInitiative = useCallback((initiative, index) => {
    return (
      <TableRow key={initiative.id}>
        <TableCell>
          <strong>{initiative.numbering}</strong>
        </TableCell>
        <TableCell className="col-6">
          <a className="link-dark-no-underline" href={initiative.deliverables_path}>
            {initiative.description}
          </a>
        </TableCell>
        <TableCell>
          <div className="d-flex">
            <TaskStatus
              status="due_soon"
              count={initiative.deliverables_status_info.due_soon}
              url={initiative.deliverables_status_info.url}
              initiativeNumber={initiative.numbering}
            />
            <TaskStatus
              status="overdue"
              count={initiative.deliverables_status_info.overdue}
              url={initiative.deliverables_status_info.url}
              initiativeNumber={initiative.numbering}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className="d-flex">
            <TaskStatus
              status="due_soon"
              count={initiative.measures_status_info.due_soon}
              url={initiative.measures_status_info.url}
              initiativeNumber={initiative.numbering}
            />
            <TaskStatus
              status="overdue"
              count={initiative.measures_status_info.overdue}
              url={initiative.measures_status_info.url}
              initiativeNumber={initiative.numbering}
            />
          </div>
        </TableCell>
        <TableCell className="col-2">
          {initiative.open_report_info ? (
            <div className="d-flex">
              <div className="me-3 position-relative">
                <a href={initiative.open_report_info.url}>
                  <ReportIcon {...initiative.open_report_info} role="lead" showTooltip />
                </a>
              </div>
              <div className="d-flex justify-content-between w-100">
                <div className="me-3">{new Date(initiative.open_report_info.due_date).toLocaleDateString({}, { timeZone: 'UTC' })}</div>
                <a className="link-default" href={initiative.open_report_info.archive_url}>
                  Archive
                </a>
              </div>
            </div>
          ) : (
            <div>Progress monitoring not configured</div>
          )}
        </TableCell>
      </TableRow>
    );
  }, []);

  return (
    <Paper className="px-4 py-2" elevation={3}>
      <h4>My Initiatives</h4>
      <Table size={'small'}>
        <TableHead>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell className="text-center">Deliverables</TableCell>
          <TableCell className="text-center">Measures</TableCell>
          <TableCell>Progress Reports</TableCell>
        </TableHead>
        <TableBody>
          {props.initiatives
            .sort((a, b) => {
              const [aSpNumber, aInitNumber] = a.numbering.split('.').map((num) => parseInt(num));
              const [bSpNumber, bInitNumber] = b.numbering.split('.').map((num) => parseInt(num));
              if (aSpNumber < bSpNumber) {
                return -1;
              } else if (aSpNumber == bSpNumber) {
                return aInitNumber - bInitNumber;
              } else {
                return 1;
              }
            })
            .map((initiative, i) => renderInitiative(initiative, i))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
