import * as React from 'react';
import Layout from '../layouts/application';
import iconMapping from '../../components/strategic_priority_category/icon_mapping';
import Tooltip from '@mui/material/Tooltip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import infoIcon2x from 'images/info_icon@2x.png';
import infoIconSp2x from 'images/info_icon_sp@2x.png';
import _ from 'lodash';

// eslint-disable-next-line
const barValue = (value, target, type) => {
  if (type == 'percentage') {
    return value;
  } else {
    return Math.min(100, (value / target) * 100);
  }
};

// eslint-disable-next-line
const barColor = (value, target, type, direction) => {
  if (direction == 'at_least') {
    if (value / target >= 1) {
      return '#00CB65';
    } else if (value / target >= 0.9) {
      return '#FFD800';
    } else {
      return '#FF006C';
    }
  } else if (direction == 'at_most') {
    if (value / target <= 1) {
      return '#00CB65';
    } else if (value / target <= 1.1) {
      return '#FFD800';
    } else {
      return '#FF006C';
    }
  }
};

const ProgressBarWithTarget = (props) => {
  const { value, target, type, targetColor, direction } = props;

  let barValues = {};
  if (type == 'percentage') {
    barValues['current'] = value;
    if (target > value) {
      barValues['gap'] = target - value;
      barValues['target'] = 100 - target;
    } else {
      barValues['target'] = 100 - value;
    }
  } else {
    barValues['current'] = Math.min(100, (value / target) * 100);
    if (target > value) {
      barValues['gap'] = ((target - value) / target) * 100;
    }
  }

  return (
    <div className="position-relative">
      <ProgressBar style={{ height: 10, borderRadius: 6 }}>
        {barValues['current'] > 0 && <ProgressBar now={barValues['current']} style={{ backgroundColor: barColor(value, target, type, direction) }} />}
        {barValues['gap'] > 0 && (
          <ProgressBar
            style={{
              backgroundColor: targetColor == 'black' ? '#707070' : '#24618D',
              opacity: '0.35',
            }}
            now={barValues['gap']}
          />
        )}
        {barValues['target'] > 0 && (
          <ProgressBar
            style={{
              backgroundColor: targetColor == 'black' ? '#CDCDCD' : '#24618D',
              backgroundSize: '0.5em 0.5em',
            }}
            now={barValues['target']}
            striped
          />
        )}
      </ProgressBar>
      {type == 'percentage' && (
        <div
          style={{
            width: 2,
            height: 12,
            backgroundColor: targetColor == 'black' ? 'black' : 'white',
            backgroundOpacity: 0,
            position: 'absolute',
            transform: 'translateX(-50%)',
            top: '-1px',
            left: `${target}%`,
          }}
        />
      )}
      {type == 'total_count' && value > target && (
        <div
          style={{
            width: 2,
            height: 12,
            backgroundColor: targetColor == 'black' ? 'black' : 'white',
            position: 'absolute',
            transform: 'translateX(-50%)',
            top: '-1px',
            left: `${(target / value) * 100}%`,
          }}
        />
      )}
    </div>
  );
};

const ProgressBarWithCurrentAndTarget = (props) => {
  const { value, target, type } = props;

  return (
    <div className="position-relative">
      {type == 'percentage' && value > 0 && (
        <div className="w-100" style={{ height: 30 }}>
          <div
            style={{
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: `${value}%`,
              textAlign: 'center',
              transform: 'translateX(-50%)',
              lineHeight: 1.1,
            }}
          >
            <div className="progress-bar-label">CURRENT</div>
            <div style={{ fontWeight: 'bold' }}>{props.value}%</div>
          </div>
        </div>
      )}
      {type == 'total_count' && value > 0 && (
        <div className="w-100" style={{ height: 30 }}>
          <div
            style={{
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: `${Math.min(100, (value / target) * 100)}%`,
              textAlign: 'center',
              transform: 'translateX(-50%)',
              lineHeight: 1.1,
            }}
          >
            <div className="progress-bar-label">CURRENT</div>
            <div style={{ fontWeight: 'bold' }}>{props.value.toLocaleString()}</div>
          </div>
        </div>
      )}
      <ProgressBarWithTarget {...props} targetColor="white" />
      {type == 'percentage' && (
        <div className="w-100 pt-1" style={{ height: 37 }}>
          <div
            style={{
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: `${target}%`,
              textAlign: 'center',
              transform: 'translateX(-50%)',
              lineHeight: 1.1,
            }}
          >
            <div className="progress-bar-label">TARGET</div>
            {props.target}%
          </div>
        </div>
      )}
      {type == 'total_count' && (
        <div className="w-100 pt-1" style={{ height: 37 }}>
          <div
            style={{
              whiteSpace: 'nowrap',
              position: 'absolute',
              left: `${Math.min(100, (target / value) * 100)}%`,
              textAlign: 'center',
              transform: 'translateX(-50%)',
              lineHeight: 1.1,
            }}
          >
            <div className="progress-bar-label">TARGET</div>
            {props.target.toLocaleString()}
          </div>
        </div>
      )}
    </div>
  );
};

const Measure = (props) => {
  const reporterName = props.results_reporter ? `${props.results_reporter.first_name} ${props.results_reporter.last_name || ''}` : '';

  return (
    <div className="measure mr-3 mt-3 position-relative">
      <Tooltip
        title={
          <>
            <div style={{ marginLeft: -15, marginRight: -15 }}>
              <div className="mb-2">{props.name}</div>
              <div className="d-flex justify-content-between">
                <span className="mb-2 mt-1">
                  Results Reporter: <strong>{`${reporterName}`}</strong>
                </span>
                <span>{}</span>
              </div>
            </div>
            <ProgressBarWithCurrentAndTarget
              value={props.progress}
              target={props.target_result_value}
              type={props.target_result_type}
              direction={props.direction}
            />
          </>
        }
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: '#346189',
              padding: '20px 35px',
              fontWeight: 400,
              fontSize: 14,
              width: 500,
              opacity: 1,
              lineHeight: 1.1,
              '& .MuiTooltip-arrow': {
                color: '#346189',
                opacity: 1,
              },
            },
          },
        }}
        placement="right"
      >
        <img src={infoIcon2x} style={{ width: 15, height: 15, position: 'absolute', top: 5, right: 5 }} />
      </Tooltip>
      <a
        href={props.numbering ? props.search_result_path.split('#')[0] + '#' + props.numbering : props.search_result_path.split('#')[0]}
        className="h-100"
      >
        <div className="measure-name mb-2">{props.name}</div>
        <ProgressBarWithTarget
          value={props.progress}
          target={props.target_result_value}
          type={props.target_result_type}
          direction={props.direction}
          targetColor="black"
        />
        <div className="mt-2 text-muted text-uppercase fs-8">
          {props.direction
            .split('_')
            .map((w) => _.capitalize(w))
            .join(' ')}{' '}
          {props.target_result_value}
          {props.target_result_type === 'percentage' ? '%' : ''}
        </div>
      </a>
    </div>
  );
};

const Initiative = (props) => {
  const leadName = props.assigned_to_user ? `${props.assigned_to_user.full_name || ''}` : '';

  return (
    <a href={props.deliverables_path} className="text-decoration-none">
      <div className="initiative position-relative">
        <Tooltip
          title={
            <>
              <div className="mb-2">{props.description}</div>
              <div className="d-flex justify-content-between mt-2">
                <span>
                  Lead: <strong>{`${leadName}`}</strong>
                </span>
                <span>{props.health && _.startCase(props.health.split('_').join(' '))}</span>
              </div>
            </>
          }
          arrow
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#346189',
                padding: '20px',
                fontWeight: 400,
                fontSize: 14,
                width: 400,
                opacity: 1,
                lineHeight: 1.1,
                '& .MuiTooltip-arrow': {
                  color: '#346189',
                  opacity: 1,
                },
              },
            },
          }}
        >
          <img src={infoIcon2x} style={{ width: 15, height: 15, position: 'absolute', top: 5, right: 5 }} />
        </Tooltip>
        {props.numbering} {props.name}
      </div>
    </a>
  );
};

const Container = (props) => {
  if (props.plan == null) {
    return;
  }
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className="accordion-button font-weight-bold">
          <h5>Mission Measures</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="d-flex flex-wrap mission-measures">
            {props.plan.measures.map((measure) => (
              <div className="col-3" key={measure.id}>
                <div className="me-3 mb-3">
                  <Measure {...measure} numbering={null} key={measure.id} />
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className="mt-4">
        <AccordionSummary className="accordion-button font-weight-bold" expandIcon={<ExpandMoreIcon />}>
          <h5>Strategic Priority & Initiative Measures</h5>
        </AccordionSummary>
        <AccordionDetails>
          {props.plan.strategic_priorities.map((priority) => (
            <div key={priority.id}>
              <Accordion defaultExpanded elevation={0} className="mt-3">
                <AccordionSummary className="strategic-priority accordion-button position-relative" expandIcon={<ExpandMoreIcon />}>
                  <Tooltip
                    title={priority.description}
                    arrow
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#346189',
                          padding: '20px',
                          fontWeight: 400,
                          fontSize: 14,
                          width: 400,
                          lineHeight: 1.1,
                          opacity: 1,
                          '& .MuiTooltip-arrow': {
                            color: '#346189',
                            opacity: 1,
                          },
                        },
                      },
                    }}
                  >
                    <img src={infoIconSp2x} style={{ height: 25, position: 'absolute', top: 5, right: 5 }} />
                  </Tooltip>
                  <div className="d-flex align-items-center w-100">
                    <div className="m-4 border ms-0" />
                    <img
                      src={iconMapping(priority.strategic_priority_category_id)}
                      style={{ width: '80%', maxWidth: 60, minWidth: '40px', float: 'right' }}
                    ></img>
                    <div className="ms-2 p-2">
                      <div data-cy="sp-description-r" className="d-flex align-items-center pt-1 pb-1">
                        <div className="plan-element-type">
                          Strategic
                          <br />
                          Priority
                          <br />
                          <div className="numbering">{priority.numbering}</div>
                        </div>
                        <h5>{priority.description}</h5>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {(priority.measures.length > 0 || priority.initiatives.length > 0) && (
                    <div className="d-flex mt-3" style={{ marginLeft: 85 }}>
                      <div className="col-3 ps-2 pe-5 ml-0">
                        <h6>Strategic Priority Measures</h6>
                        {priority.measures.map((measure) => (
                          <Measure {...measure} numbering={priority.numbering} key={measure.id} />
                        ))}
                      </div>
                      <div className="col-9">
                        <h6>Initiatives</h6>
                        <div className="d-flex flex-wrap">
                          {priority.initiatives.map((initiative) => (
                            <div className="col-4 pe-5" key={initiative.id}>
                              <Initiative {...initiative} />
                              <div className="d-flex">
                                <div className="w-100 measure-list">
                                  {initiative.measures.map((measure) => (
                                    <Measure {...measure} numbering={initiative.numbering} key={measure.id} />
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default (props, railsContext) => {
  return () => (
    <Layout railsContext={railsContext}>
      <Container {...props}></Container>
    </Layout>
  );
};
