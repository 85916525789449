// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Do this as early as possible so we catch any startup exceptions
import * as Sentry from '@sentry/browser'; // eslint-disable-line
// if (process.env.SENTRY_DSN) {
//   Sentry.init({ dsn: `${process.env.SENTRY_DSN}`, release: window.SENTRY_RELEASE, environment: `${process.env.RAILS_ENV}` })
// } else {
//   console.warn("Sentry tracking not enabled, process.env.SENTRY_DSN not found.")
// }

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('@rails/ujs').start(); // eslint-disable-line
require('@rails/activestorage').start(); // eslint-disable-line
import 'bootstrap';

// require("turbolinks").start()
// require("channels")
// require("jquery")

// $(document).on('turbolinks:load', () => {
//   console.log('turbolinks:load')
//   registerReactComponents()
// })

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// eslint-disable-next-line
const images = require.context('../images', true);
// eslint-disable-next-line
const imagePath = (name) => images(name, true);

import registerReactComponents from '../src/javascript/register_react_components';
import registerGlobalEventListeners from '../src/javascript/register_global_event_listeners';
import { initHubspotChat } from '../src/javascript/global/init_hubspot';
import { initFeatureFlagClient } from '../src/javascript/global/init_feature_flag_client';

document.addEventListener('DOMContentLoaded', () => {
  initFeatureFlagClient();
  registerReactComponents();
  registerGlobalEventListeners();
  initHubspotChat();
});
