import ReactOnRails from 'react-on-rails';

import * as StrategicPlanComponents from './registrations/strategic_plan_components';
import PlanSearch from './views/search';

import * as MyWorkComponents from './registrations/my_work_components';
import * as ScoreCardComponents from './registrations/scorecard_components';
import * as ProgressMonitoringComponents from './registrations/progress_monitoring_components';
import * as CareComponents from './registrations/care_components';
import * as SurveysComponents from './registrations/surveys_components';
import * as SharedComponents from './registrations/shared_components';

function registerReactComponents() {
  ReactOnRails.register({
    PlanSearch,
    ...StrategicPlanComponents,
    ...MyWorkComponents,
    ...ScoreCardComponents,
    ...ProgressMonitoringComponents,
    ...CareComponents,
    ...SurveysComponents,
    ...SharedComponents,
  });
}

export default registerReactComponents;
